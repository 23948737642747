<template>
  <div v-if="permission.access">
    <b-card no-body v-if="permission.create || !form.id">
      <b-card-body>
        <form @submit.prevent="save()" @keydown="form.onKeydown($event)">
          <b-row>
            <b-col cols="12" xl="4">
              <div class="form-group">
                <label>Name*</label>
                <Input
                    v-model="form.name"
                    placeholder="Enter Division Name"
                    type="text"
                />
                <has-error
                    :form="form"
                    field="name"
                ></has-error>
              </div>
            </b-col>

            <b-col cols="12" xl="3">
              <div class="form-group">
                <label>Bangla Name</label>
                <Input
                    v-model="form.bn_name"
                    placeholder="Enter Division's Bangla Name"
                    type="text"
                />
                <has-error
                    :form="form"
                    field="bn_name"
                ></has-error>
              </div>
            </b-col>

            <b-col cols="12" xl="3">
              <div class="form-group">
                <label>Url</label>
                <Input
                    v-model="form.url"
                    placeholder="Enter The Url"
                    type="text"
                />
                <has-error
                    :form="form"
                    field="designation"
                ></has-error>
              </div>
            </b-col>

            <b-col cols="12" xl="2">
              <div class="form-group" style="margin-top: 4px">
                <b-row class="pt-1 text-right">
                  <b-col>
                    <b-button
                        variant="danger"
                        @click="clear()"
                    >
                      Clear
                    </b-button>
                    <b-button
                        variant="primary"
                        class="ml-1"
                        type="submit"
                        :disabled="form.busy"
                    >
                      {{ form.id ? 'Update' : 'Add' }}
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </form>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body>
        <h5>Division Lists</h5>
      </b-card-body>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th class="text-center">Sl.</th>
            <th>Name</th>
            <th>Bn Name</th>
            <th>Url</th>
            <th>Created At</th>
            <th v-if="permission.edit || permission.delete" class="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(division, index) in divisions" :key="index"
              v-if="divisions.length">
            <th class="text-center">{{ index + 1 }}</th>
            <td>{{ division.name ? division.name : "Not Provided" }}</td>
            <td>{{ division.bn_name ? division.bn_name : "Not Provided"  }}</td>
            <td>
              <a :href="'https://'+division.url" target="_blank" v-if="division.url">{{ division.url }}</a>
              <span v-else>Not Provided</span>
            </td>
            <td>{{ division.created_at | date_time_format }}</td>
            <td v-if="permission.edit || permission.delete" class="text-center">
              <button
                  v-if="permission.edit"
                  class="btn btn-primary btn-sm"
                  @click="edit(division.id)"
              >
                <feather-icon icon="EditIcon"/>
              </button>
              <button
                  v-if="permission.delete"
                  class="btn btn-danger btn-sm ml-1"
                  @click="destroy(division.id)"
              >
                <feather-icon icon="TrashIcon"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardBody
} from "bootstrap-vue";
import {HasError, AlertError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  props: [],
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        bn_name: null,
        url: null,
      }),
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
    BCard,
    BCardBody
  },
  mounted() {
    this.$store.dispatch("getDivision");
  },
  computed: {
    divisions() {
      return this.$store.getters.getDivision;
    },
    permission() {
      return this.$store.getters.getPermissions.division;
    },
  },
  methods: {
    clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.bn_name = null;
      this.form.url = null;
    },
    save() {
      if (!this.form.id) {
        this.form.post('/app/division')
            .then(response => {
              this.s(response.data.message);
              this.clear();
              this.$store.dispatch('getDivision');
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      } else {
        this.form.put('/app/division/' + this.form.id)
            .then(response => {
              this.s(response.data.message);
              this.clear();
              this.$store.dispatch('getDivision');
            })
            .catch((e) => {
              this.e(e.data.error);
            });
      }
    },
    edit(id) {
      axios
          .get("/app/division/" + id + "/edit")
          .then((res) => {
            this.form.id = res.data.division.id;
            this.form.name = res.data.division.name;
            this.form.bn_name = res.data.division.bn_name;
            this.form.url = res.data.division.url;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    Delete(id) {
      axios
          .delete("/app/division/" + id)
          .then((response) => {
            this.s(response.data.message);
            this.$store.dispatch('getDivision');
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    destroy(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.Delete(id);
        }
      });
    },
  }
};
</script>

<style scoped>
</style>